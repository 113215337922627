export type OrientationProps =
  | "row"
  | "column"
  | "row-reverse"
  | "column-reverse";

export interface ResponsiveOrientationProps {
  responsive: OrientationProps;
  desktop: OrientationProps;
}

export interface ModalResponsiveOrientationProps {
  responsive: OrientationProps;
  desktop: OrientationProps | "triangle";
}

export interface ResponsiveOrderProps {
  responsive: number;
  desktop: number;
}

export const translateOrientation = (
  actionOrientation:
    | OrientationProps
    | "triangle"
    | ResponsiveOrientationProps
    | ModalResponsiveOrientationProps
): string => {
  if (typeof actionOrientation === "string") {
    return `orientation-${actionOrientation}`;
  } else {
    return `orientation-desktop-${actionOrientation.desktop} orientation-responsive-${actionOrientation.responsive}`;
  }
};

export const translateOrder = (
  order: number | ResponsiveOrderProps
): string => {
  if (typeof order === "number") {
    return `order-${order}`;
  } else {
    return `order-desktop-${order.desktop} order-responsive-${order.responsive}`;
  }
};

/**
 * We can use this to compare CkIconNames from workshop and endconsumer
 */
export const compareIcons = (
  workshopIcons: string[],
  endConsumerIcons: string[]
): {
  missingWorkshopIcons: string[];
  missingEndConsumerIcons: string[];
  uniqueIcons: string[];
  joinedIcons: string;
} => {
  const missingWorkshopIcons = endConsumerIcons.filter(
    (icon) => !workshopIcons.includes(icon)
  );
  const missingEndConsumerIcons = workshopIcons.filter(
    (icon) => !endConsumerIcons.includes(icon)
  );

  const uniqueIcons = Array.from(
    new Set([...workshopIcons, ...endConsumerIcons])
  ).sort();

  const joinedIcons = uniqueIcons.join(" | ");

  return {
    missingWorkshopIcons,
    missingEndConsumerIcons,
    uniqueIcons,
    joinedIcons,
  };
};

export default compareIcons;
